export const Environment = {
	AppMeta: {
		Environment: 'STAGE',
	},
	AuthConfig: {
		// ZEISS ID stage environment
		issuer: 'https://b2cstage.zeiss.com/483f449b-6a0d-4354-b7e7-37113e7d4ca1/v2.0/',
		clientId: 'ac5fb875-7d36-4c5a-9112-c12c4d09621f',
		discoveryDocumentUrl:
			'https://id-ip-stage.zeiss.com/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignIn',
		selfServicePortalUrl: 'https://id-stage.zeiss.com/',
	},
	API: {
		Path: 'https://func-dlp-stage-westeu-002.azurewebsites.net/api',
		Headers: {
			Authorization: '',
		},
		Params: {},
		Create: {
			Path: 'https://func-eop-ac-stage-westeu-002.azurewebsites.net/api',
			Headers: {
				'x-functions-key': 'PqQJbYkf0CqtbZyCV_mwZ6mS1CwVotEv3n2pOhAqTwKTAzFuRFJXng==',
			},
			LegalDocument: '/legal-document',
			TermsOfUse: '/Developer%20TermsofUse%20Form%20v2%20(2-6-23)%20(002).pdf',
			Authorization: '/Developer%20Authorization%20Form%20v2%20(2-6-23)_with_hyperlinks.pdf',
		},
		Whitelist: {
			Path: 'https://esb-stage.zeiss.com',
			AddUser: '/public/api/experience/vis/evt/account-whitelist/user',
			AddUsers: '/public/api/experience/vis/evt/account-whitelist/users',
			DownloadCsv: '/public/api/experience/vis/evt/account-whitelist/users',
			Headers: {
				'Ocp-Apim-Subscription-Key': 'b7c1254739e14fb499391dddd186e34e',
			},
		},
	},
	RXPassUploadMaxFileSizeinBytesBinary: 8388608,
	RXPassUploadMaxFileSizeinMBText: '8MB',
	appInsights: {
		activated: true,
		instrumentationKey: 'a8d4f2b6-07b8-49ab-a64d-847f3c9d8b6b',
	},
	AzureAppConfig: {
		ConnectionString: 'Endpoint=https://appcs-evt-stage-westeu.azconfig.io;Id=EjNA-l9-s0:esPBM82xYDLbxA5bPSHN;Secret=oxtZ7xdFKpt1aRYAy+v/jH62GzpiJFOvIDksCJkw4PI=',
	},
};
